$dark-mode: #272935;
$dark-mode-light: #464852;

body.dark {
  background-color: $dark-mode;
  color: rgba(white, 0.75);

  .text-muted {
    color: rgba(white, 0.4) !important;
  }

  nav.navigation {
    background-color: $dark-mode-light;

    ul {
      li {
        a {
          color: rgba(white, 0.7);

          &:hover,
          &:focus {
            background-color: $dark-mode-light + 20;
          }
        }
      }
    }
  }

  .layout {
    .content {
      .sidebar-group {
        .sidebar {
          background-color: $dark-mode-light;

          .list-group-item .users-list-body {
            p {
              color: rgba(white, 0.4);
            }

            .users-list-action {
              background-color: $dark-mode-light;
              box-shadow: -8px 1px 10px 5px $dark-mode-light;
            }
          }

          & > form {
            input[type='text'] {
              background-color: $dark-mode-light + 20;
              color: rgba(white, 0.7);

              &::placeholder {
                color: rgba(white, 0.5);
              }
            }
          }
        }
      }

      .chat {
        .chat-header {
          border-bottom-color: $dark-mode-light - 20;
        }

        .chat-body {
          .messages {
            .message-item {
              .message-content {
                background-color: $dark-mode-light;
              }

              &.outgoing-message .message-content {
                background-color: rgba($color-primary, 0.3);
              }
            }
          }
        }

        .chat-footer {
          border-top-color: $dark-mode-light - 20;

          form {
            background-color: $dark-mode-light;

            input[type='text'] {
              color: rgba(white, 0.7);

              &::placeholder {
                color: rgba(white, 0.5);
              }
            }
          }
        }
      }
    }
  }

  &.rtl {
    .layout {
      .content {
        .sidebar-group {
          .sidebar {
            .list-group-item .users-list-body {
              .users-list-action {
                box-shadow: 8px 1px 10px 5px $dark-mode-light !important;
              }
            }
          }
        }
      }
    }
  }

  .modal-content {
    background-color: $dark-mode-light;

    .modal-header {
      background-color: $dark-mode-light + 20;
    }
  }

  .list-group-item {
    border-color: $dark-mode-light + 20;
  }

  .dropdown-menu {
    background-color: $dark-mode-light + 20;
    border-color: transparent;

    .dropdown-item {
      color: rgba(white, 0.7);

      &:focus,
      &:hover,
      &:active,
      &.active {
        background-color: $dark-mode-light;
      }
    }
  }

  input[type='text'],
  textarea,
  select {
    background-color: $dark-mode-light + 20;
    color: rgba(white, 0.7);
    border-color: $dark-mode-light + 40;

    &:focus {
      color: rgba(white, 0.7);
      background-color: $dark-mode-light + 20;
      border-color: $dark-mode-light + 100;
    }

    &::placeholder {
      color: rgba(white, 0.5);
    }
  }

  .custom-control-label::before {
    background-color: $dark-mode-light + 20;
    border-color: $dark-mode-light + 40;
  }

  .input-group-text {
    background-color: $dark-mode-light;
    color: rgba(white, 0.7);
    border-color: $dark-mode-light + 40;
  }

  .nav-tabs .nav-link.active {
    border-color: $dark-mode-light + 20;
    border-bottom-color: $dark-mode-light;
    background-color: $dark-mode-light;
    color: rgba(white, 0.7);
  }

  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border-color: $dark-mode-light + 20;
    border-bottom-color: $dark-mode-light;
  }

  .nav-tabs {
    border-bottom-color: $dark-mode-light + 20;
  }

  .custom-file-label {
    background-color: $dark-mode-light + 20;
    border-color: $dark-mode-light + 40;
    color: rgba(white, 0.7);

    &:after {
      background-color: $dark-mode-light;
      color: rgba(white, 0.7);
    }
  }

  .btn {
    &.btn-light {
      background-color: $dark-mode-light + 20;
      border-color: transparent;
      color: rgba(white, 0.7);

      &:focus,
      &:hover {
        border-color: transparent !important;
        background-color: $dark-mode-light + 20 !important;
        color: rgba(white, 0.8) !important;
        box-shadow: 0 0 0 0.2rem rgba($dark-mode-light + 20, 0.6) !important;
      }
    }
  }

  figure.avatar.avatar-state-danger:before,
  figure.avatar.avatar-state-dark:before,
  figure.avatar.avatar-state-info:before,
  figure.avatar.avatar-state-light:before,
  figure.avatar.avatar-state-primary:before,
  figure.avatar.avatar-state-secondary:before,
  figure.avatar.avatar-state-success:before,
  figure.avatar.avatar-state-warning:before {
    border-color: $dark-mode-light;
  }

  &.form-membership {
    background: url(/static/media/bg.78f5154d.png) $dark-mode-light;

    .btn-outline-light {
      color: rgba(white, 0.95);
    }

    .form-wrapper {
      background: $dark-mode-light;

      .logo {
        background: $dark-mode-light;

        svg {
          fill: rgba(white, 0.95);
        }
      }

      .form-control {
        background: $dark-mode-light;
        border-color: $dark-mode-light + 20;

        &:focus {
          color: white;
        }
      }
    }
  }
}
