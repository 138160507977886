#test {
  .row-dragging {
    background: #fafafa !important;
    border: 1px solid #ccc !important;
  }

  .row-dragging td {
    padding: 16px !important;
    visibility: hidden !important;
  }

  .row-dragging .drag-visible {
    visibility: visible !important;
  }
}

.setMenuHover {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 19px;
  background: rgb(230, 230, 230);
  padding: 14px;
  width: 69px;
  margin: auto;
  color: white;

  &:hover,
  &:active,
  &:focus,
  .active {
    background: #0abb87;
  }
}
.setMenuHover.active {
  background: #0abb87;
}

.setOptionStatus {
  // padding: 24px;
  // margin: auto;
  // border: 1px solid;
  // border-radius: 45px;
  width: 84px;

  .TextOptionStatus {
    width: 84px;
    // font-size: 34px;
    // color: rgb(0, 0, 0);
  }

  &:hover,
  &:active,
  &:focus,
  .active {
    border-color: #0abb87;
    color: #0abb87;
    .TextOptionStatus {
      // color: #0abb87;
      filter: invert(47%) sepia(99%) saturate(398%) hue-rotate(112deg) brightness(101%)
        contrast(92%);
    }
    .setOptionStatusText {
      color: #0abb87;
    }
  }
}

a.setTextHover {
  color: rgb(0, 0, 0);
  &:hover,
  &:active,
  &:focus,
  .active {
    border-color: #0abb87;
    color: #0abb87;
    i.TextOptionStatus {
      color: #0abb87;
    }
    .setOptionStatusText {
      color: #0abb87;
    }
  }
}

.setOptionStatusText {
  width: 84px;
}

span.setButtomProcessMsg.badge {
  color: #fff;
  cursor: pointer;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 6px;
  padding-bottom: 4px;
  &.badge-warning {
    background-color: #ff9d36;
  }

  &.badge-dark.badge-pill {
    padding-right: 10px;
  }
}

.setPaddingBadgeProcess {
  margin-left: 0.4rem !important;
}

.chatBorder {
  margin-top: 1rem;
  background: #f5f5f5;
  border-radius: 9px;
}

.ShadowBorder {
  box-shadow: 0px 3px 2px 1px #cacaca;
}

.ChatMsgCus {
  background-color: #343a40 !important;
  color: #fff !important;
}

.ChatMsgOper {
  background-color: #fff !important;
}

.react-datepicker-wrapper {
  display: block !important;
}

.text-h {
  color: #1890ff;
}

.btn-primarys {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;

  &:hover,
  &:active,
  &:focus,
  .active {
    color: #fff;
  }
}

input[type='file'].novisible {
  display: none;
}
input[type='file'].novisible + a {
  color: '#fff';
  // width:200px;
  // height:200px;
  // border-radius:50%;
  // border:2px solid #999;
  // display:inline-flex;
  // justify-content:center;
  // align-items:center;
}
