.chat {
  // flex: 1;
  display: flex;
  flex-direction: column;

  .chat-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid white - 30;
    padding-bottom: 20px;

    .chat-header-user {
      display: flex;
      align-items: center;

      figure {
        margin-right: 1rem;
      }

      h5 {
        font-size: $default-font-size + 2;
        font-weight: 600;
        margin-bottom: 0;
        line-height: 1;
      }
    }

    .chat-header-action {
      ul {
        margin-bottom: 0;

        & > li {
          & > a {
            font-size: $default-font-size + 2;
          }
        }
      }
    }
  }

  .chat-body {
    // flex: 1;
    margin: 20px 0;

    &:not(.no-message) {
      overflow: auto;
    }

    &.no-message {
      user-select: none;
      .no-message-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        i {
          font-size: 7em;
          opacity: 0.15;
          margin-bottom: 15px;
        }
        p {
          font-size: $default-font-size + 2;
          color: black + 130;
        }
      }
    }

    .messages {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .message-item {
        max-width: 75%;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        .message-content {
          background: white;
          border-radius: 5px;
          padding: 10px 20px;

          &.message-file {
            display: flex;
            align-items: center;
            word-break: break-word;

            .file-icon {
              font-size: $default-font-size + 10;
              margin-right: 1rem;
              color: black + 130;
            }

            ul {
              margin-top: 3px;
              margin-bottom: 0;
              li.list-inline-item {
                margin-left: 0;
                margin-right: 0.5rem;
              }
            }
          }
        }

        .message-action {
          color: black + 130;
          margin-top: 5px;
          font-style: italic;
          font-size: $default-font-size - 2;

          .ti-double-check {
            &:before,
            &:after {
              content: '\e64c';
              font-family: 'themify-icons';
            }

            &:after {
              margin-left: -4px;
            }
          }
        }

        &.outgoing-message {
          margin-left: auto;

          .message-content {
            background-color: white - 50;
          }

          .message-action {
            text-align: right;
          }
        }
      }
    }
  }

  .chat-footer {
    border-top: 2px solid white - 30;
    padding-top: 20px;

    form {
      display: flex;
      padding: 10px;
      border-radius: 5px;
      align-items: center;
      background-color: white;

      .form-buttons {
        display: flex;

        .btn {
          margin-left: 0.5rem;
        }
      }

      input[type='text'] {
        border: none;
        background-color: inherit;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  & + .sidebar-group {
    margin-right: 0px;

    .sidebar {
      margin-left: 8px;
      margin-right: 0px;
      // width: 540px;
    }
  }
}

.count-unread {
  background-color: #28a745;
  color: #fff;
  width: 24px;
  height: 24px;
  padding: 2px;
}

.count-unread-small {
  background-color: #ff4d4f;
  color: #fff;
  width: 24px;
  height: 24px;
  padding: 2px;
  font-size: 0.7rem;
  border: 1px solid yellow;
}

.my-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: -webkit-center;
  z-index: 999;
}

.my-modal-img {
  // width: 100%;
  max-width: 1000px;
  margin-top: 16px;
  z-index: 999;
}

.over-txts {
  max-width: 160px;
  white-space: nowrap !important;
  overflow: hidden !important;
  box-sizing: border-box;
  text-overflow: ellipsis !important;
}

.over-txt2 {
  width: 180px !important;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  text-overflow: ellipsis;
}

.over-txt-temp {
  width: 100%;
  // max-width: 480px;
  white-space: nowrap !important;
  overflow: hidden !important;
  box-sizing: border-box;
  text-overflow: ellipsis !important;
  background: #00bcd4;
  border-color: #00bcd4;
  color: #fff;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
}

.row-temp {
  height: fit-content;
  max-height: 560px;
  overflow: scroll;
  // background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 4px;
}

.all-center {
  text-align: -webkit-center !important;
}

.fit-width {
  width: fit-content !important;
}

th {
  font-weight: bold !important;
}

.pointer {
  cursor: pointer;
}

#form-search {
  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
  }
  input {
    width: 100%;
    border: 1px solid gray !important;
    border-radius: 4px;
    padding: 2px 2px 2px 8px;
  }
}
