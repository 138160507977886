body.rtl {
  direction: rtl;

  .btn-icon {
    margin-right: 0 !important;
    margin-left: 0.5rem;
  }

  .mr-3.item-rtl {
    margin-right: 0 !important;
    margin-left: 1rem;
  }

  .mr-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  ul.nav.nav-tabs {
    padding-right: 0;
  }

  .files ul.list-inline,
  ul.social-links {
    padding-right: 0;
    li.list-inline-item {
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }

  .modal {
    .modal-content {
      text-align: right;

      .modal-header .modal-title i {
        margin-right: 0;
        margin-left: 0.7rem;
      }
    }

    .modal-footer {
      justify-content: end;
    }
  }

  .input-group {
    .input-group-prepend {
      .input-group-text {
        border-radius: 5px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .form-control {
      border-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .custom-file-label::after {
    right: auto;
    left: 0;
    border-radius: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  nav.navigation {
    ul {
      li {
        a {
          &.notifiy_badge {
            &:before {
              left: 10px;
              right: auto !important;
            }
          }
        }
      }
    }
  }

  .sidebar-group {
    .sidebar {
      margin-left: 8px;
      margin-right: 0 !important;
      text-align: right;

      & > header {
        ul.list-inline {
          li.list-inline-item {
            margin-left: 0 !important;
            margin-right: 0.5rem !important;
          }
        }
      }

      .list-group-item {
        &.open-chat {
          &:before {
            left: auto;
            right: 0;
          }
        }

        figure {
          margin-right: 0 !important;
          margin-left: 1rem;
        }

        .avatar-group figure.avatar {
          margin-right: -2rem !important;
        }

        .users-list-body {
          text-align: right;

          .users-list-action {
            right: auto !important;
            left: 0;
            box-shadow: 8px 1px 10px 5px #fff !important;
          }
        }
      }
    }
  }

  .list-group {
    padding-right: 0;
  }

  .chat {
    .chat-header {
      .chat-header-user {
        text-align: right;

        figure {
          margin-right: 0 !important;
          margin-left: 1rem;
        }
      }

      .chat-header-action {
        .list-inline-item {
          margin-right: 0.5rem !important;
          margin-left: 0 !important;
        }
      }
    }

    .chat-body {
      .messages {
        .message-item {
          text-align: right;

          ul {
            padding-right: 0 !important;
          }

          .message-content {
            &.message-file {
              .file-icon {
                margin-right: 0 !important;
                margin-left: 1rem;
              }
            }
          }

          &.outgoing-message {
            margin-left: 0 !important;
            margin-right: auto;
          }
        }
      }
    }

    & + .sidebar-group {
      .sidebar {
        margin-left: 0 !important;
        // margin-right: 30px !important;
      }
    }
  }
}
