#chat-view {
  .messages {
    font-size: 1rem;
    color: #000;
    text-align: right;
  }

  .times {
    font-size: 0.8rem;
  }
}

#profile {
  .nav-tabs {
    border-bottom: 1px solid #dee2e6;
    justify-content: center;
  }
}

.fs0-7 {
  font-size: 0.7rem;
}

.fs0-8 {
  font-size: 0.8rem;
}

.fs1 {
  font-size: 1rem;
}

.fs1-2 {
  font-size: 1.2rem;
}

.fs1-4 {
  font-size: 1.4rem;
}

.cut_txt {
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  text-overflow: ellipsis;
}
