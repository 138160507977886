.temp-off {
  margin: 0px 0px 0px 8px;
  width: 0px;
  transition: 0.5s;
  position: relative;
  bottom: -3000px;
}

.temp-on {
  margin: 0px 0px 0px 8px;
  width: 1480px;
  height: 100%;
  transition: 0.5s;
  position: relative;
  bottom: 0px;
  overflow: scroll;
  background: transparent;
}
